import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import App from './App';

const renderHandler = (htmlElement: HTMLElement, apiUrl: string, downKeyValidMinutes?: number) => {
    const root = ReactDOM.createRoot(htmlElement);
    root.render(
        <React.StrictMode>
            <App apiUrl={apiUrl} downloadKeyValidMinutes={downKeyValidMinutes} />
        </React.StrictMode>
    );
}

////Load react app when root element exsists (only for development)
//const rootElement = document.getElementById('RegisterHvRoot') as HTMLElement;
//rootElement && renderHandler(rootElement, "https://localhost:7153");

//Register as a Web Component (Custom HTML element)
class RegisterVanHV extends HTMLElement {

    connectedCallback() {
        
        const mountPoint = document.createElement('div');
        const asShadow = this.getAttribute('shadow')?.toLowerCase() === "true";
        const apiUrl = this.getAttribute('api') || "https://register-van-hv-api.prod.woonapps.vlaanderen.be";
        const downKeyValidMinAttr = this.getAttribute('valid-key-minutes');
        const downKeyValidMin = (downKeyValidMinAttr && +downKeyValidMinAttr) || 10;
            ;
        (asShadow ? this.attachShadow({ mode: 'open' }) : this).replaceChildren(mountPoint);
        renderHandler(mountPoint, apiUrl, downKeyValidMin);
    }    
}

customElements.define('register-van-hv', RegisterVanHV);
